import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import { setSaveIndicator, setChatData, setSelectedAppointment } from "../../actions";
import { useDispatch } from "react-redux";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import AccordionContext from "react-bootstrap/AccordionContext";
import Card from "react-bootstrap/Card";

export default function ProductList() {
  const dispatch = useDispatch();

  const [pathData, setPathData] = useState(null);
  const [sensation, setSensation] = useState("");
  const [complaint, setComplaint] = useState("");
  const [zoneData, setZoneData] = useState({});
  const [selectedZone, setSelectedZone] = useState("");
  const [show, setShow] = useState(false);
  const { userData, token, refreshToken, selectedAppointmentId } = useSelector((state) => state.counter);
  const [anteriorZones, setAnteriorZones] = useState([]);

  useEffect(() => {
    const svgObject = document.querySelector('object[data="./images/diaphragm.svg"]');
    if (svgObject) {
      svgObject.addEventListener("load", () => {
        const svgDocument = svgObject.contentDocument;
        const pathElements = svgDocument.querySelectorAll("path");

        pathElements.forEach((path) => {
          path.addEventListener("click", handlePathClick);

          // Add hover effect using inline style
          path.addEventListener("mouseover", () => {
            if (path.getAttribute("selected") != "true") {
              path.style.fill = "#A08E7A";
            }
          });

          path.addEventListener("mouseout", () => {
            if (path.getAttribute("selected") != "true") {
              path.style.fill = "#F2D5B6";
            }
          });
        });
        setTimeout(function () {
          getBody();
        }, 1000);
      });
    }
  }, []);

  useEffect(() => {
    if (selectedZone != "") {
      const matchingZone = anteriorZones.find((zone) => zone.id === Number(selectedZone));

      if (matchingZone) {
        matchingZone.pathData.style.fill = matchingZone.color;
        matchingZone.pathData.setAttribute("selected", matchingZone.selected);
      }
      syncBody();
    }
  }, [anteriorZones]);

  const getBody = async () => {
    dispatch(setSaveIndicator(true));
    const options = {
      headers: { authorization: token },
    };

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/reports/detail?id=" + selectedAppointmentId, options);
    if (Response.data.success) {
      var diaphragm = [];
      if (Response.data.result.diaphragm) {
        var result = Response.data.result.diaphragm;
        var a = document.getElementById("diaphragm");
        var svgDoc = a.contentDocument;

        for (let i = 0; i < result.length; i++) {
          const tempDiv = document.createElement("div");
          tempDiv.innerHTML = result[i].pathData;
          const svgElement = tempDiv.firstChild;

          var el = svgDoc.querySelectorAll('[data-id="' + result[i].id + '"]')[0];

          el.style.fill = result[i].color;
          el.setAttribute("selected", result[i].selected);
          console.log("el", result[i].selected);
          diaphragm.push({
            complaint: result[i].complaint,
            name: result[i].name,
            location: result[i].location,
            treatment: result[i].treatment,
            origin: result[i].origin,
            insertion: result[i].insertion,
            movement: result[i].movement,
            actions: result[i].actions,
            color: result[i].color,
            id: result[i].id,
            selected: result[i].selected,
            sensation: result[i].sensation,
            timestamp: result[i].timestamp,
            pathData: svgElement,
          });
        }
        dispatch(setSaveIndicator(false));
        setAnteriorZones(diaphragm);
      } else {
        dispatch(setSaveIndicator(false));
      }
    } else {
      dispatch(setSaveIndicator(false));
      toast.error(Response.data.message);
    }
  };

  const syncBody = async () => {
    dispatch(setSaveIndicator(true));
    const options = {
      headers: { authorization: token },
    };

    var diaphragm = [];
    if (anteriorZones) {
      for (let i = 0; i < anteriorZones.length; i++) {
        var s = new XMLSerializer();
        //if (anteriorZones[i].selected) {
        diaphragm.push({
          name: anteriorZones[i].name,
          location: anteriorZones[i].location,
          treatment: anteriorZones[i].treatment,
          origin: anteriorZones[i].origin,
          insertion: anteriorZones[i].insertion,
          movement: anteriorZones[i].movement,
          actions: anteriorZones[i].actions,
          color: anteriorZones[i].color,
          id: anteriorZones[i].id,
          selected: anteriorZones[i].selected,
          sensation: anteriorZones[i].sensation,
          timestamp: anteriorZones[i].timestamp,
          pathData: s.serializeToString(anteriorZones[i].pathData),
          complaint: anteriorZones[i].complaint,
          /////////
        });
        //}
      }

      const urlString = window.location.search;
      const urlParams = new URLSearchParams(urlString);
      const id = urlParams.get("id");

      const Response = await axios.post(
        process.env.REACT_APP_API_URL + "app/appointment/sync?practitioner=" + userData._id + "&patient=" + id + "&id=" + selectedAppointmentId,
        {
          diaphragm: diaphragm,
        },
        options
      );
      if (Response.data.status == "success") {
        var diaphragm = [];
        var result = Response.data.result.diaphragm;
        var a = document.getElementById("diaphragm");
        var svgDoc = a.contentDocument;

        for (let i = 0; i < result.length; i++) {
          const tempDiv = document.createElement("div");
          tempDiv.innerHTML = result[i].pathData;
          var el = svgDoc.querySelectorAll('[data-id="' + result[i].id + '"]')[0];
          el.style.fill = result[i].color;
          el.setAttribute("selected", result[i].selected);
        }

        dispatch(setSaveIndicator(false));
        var objectElement = document.getElementById("diaphragm");
        // Access the document inside the object element
        const svgDocument = objectElement.contentDocument;
        // Extract the SVG element from the document
        const svgElement = svgDocument.querySelector("svg");
        var svgString = new XMLSerializer().serializeToString(svgElement);
        await axios.post(
          process.env.REACT_APP_API_URL + "app/appointment/create_image?id=" + selectedAppointmentId + "_diaphragm",
          {
            image: svgString,
          },
          options
        );
      } else {
        dispatch(setSaveIndicator(false));
        toast.error(Response.data.message);
      }
    }
  };

  const handlePathClick = async (event) => {
    const path = event.target;
    setPathData(path);
    const dataId = path.getAttribute("data-id");
    if (dataId) {
      const options = {
        headers: { authorization: token },
      };
      const Response = await axios.get(process.env.REACT_APP_API_URL + "app/muscle/get?id=" + dataId, options);
      setZoneData(Response.data.result);
      setSelectedZone(dataId);
      setSensation("");
      setComplaint("");
      setShow(true);
    }
  };

  function addZone() {
    if (sensation == "") {
      return toast.error("Select sensation level");
    }

    var sensationText = "";
    var color = "";
    if (sensation <= 1) {
      sensationText = "No discomfort, feeling good";
      color = "#157347";
    } else if (sensation >= 2 && sensation <= 3) {
      sensationText = "Mild discomfort";
      color = "#FFDF28";
    } else if (sensation >= 4 && sensation <= 6) {
      sensationText = "Moderate, noticeable discomfort but still manageable.";
      color = "#FF6700";
    } else if (sensation >= 7 && sensation <= 8) {
      sensationText = "Severe discomfort, significantly impacting movement or quality of life";
      color = "#FF0808";
    } else {
      sensationText = "Extreme, debilitating discomfort needing treatment";
      color = "#530808";
    }

    const itemIndex = anteriorZones.findIndex((item) => item.id === zoneData.id);
    const updatedItems = [...anteriorZones];
    const newItem = {
      name: zoneData.name,
      location: zoneData.location,
      treatment: zoneData.treatment,
      origin: zoneData.origin,
      insertion: zoneData.insertion,
      movement: zoneData.movement,
      actions: zoneData.actions,
      selected: true,
      sensation: sensationText,
      level: sensation,
      id: zoneData.id,
      color: color,
      timestamp: Math.floor(Date.now()),
      pathData: pathData,
      complaint: complaint,
    };
    if (itemIndex !== -1) {
      updatedItems[itemIndex] = newItem;
    } else {
      updatedItems.push(newItem);
    }
    setAnteriorZones(updatedItems);
    setShow(false);
  }

  function removeZone(zoneId) {
    setSelectedZone(zoneId);
    const updatedZones = anteriorZones.map((zone) => {
      if (zone.id === zoneId) {
        return { ...zone, selected: false, sensation: "", level: 0, color: "#F2D5B6", timestamp: Math.floor(Date.now()) };
      }
      return zone;
    });
    setAnteriorZones(updatedZones);
  }

  function CustomToggle({ children, eventKey, id, muscle }) {
    const { activeEventKey } = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(eventKey, () => {});
    const isCurrentEventKey = activeEventKey === eventKey;
    return (
      <div className="row custom_muscle_action_button">
        <div className="col-sm-10" onClick={decoratedOnClick}>
          {children}
        </div>
        <div className="col-sm-2">
          <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
            <button type="button" className="btn btn-secondary btn-sm" onClick={decoratedOnClick}>
              <i className="material-icons">{isCurrentEventKey ? "expand_more" : "expand_less"}</i>
            </button>

            <button
              type="button"
              className="btn btn-secondary btn-sm"
              onClick={() => {
                dispatch(
                  setChatData({
                    openModal: true,
                    selectedAppointment: selectedAppointmentId,
                    segment: "diaphragm",
                    muscle: muscle,
                  })
                );
              }}
            >
              <i className="material-icons">chat</i>
            </button>

            <button type="button" className="btn btn-secondary btn-sm" onClick={() => removeZone(id)}>
              <i className="material-icons">delete</i>
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div>
        <div className="row">
          <div className="col-md-4">
            <div className="text-center">
              <object id="diaphragm" data="./images/diaphragm.svg" type="image/svg+xml" height="400"></object>
            </div>
          </div>
          <div className="col-md-8">
            {anteriorZones.filter((obj) => obj.selected).length == 0 && (
              <>
                <div class="alert alert-warning text-center mt-5" role="alert">
                  Please select the muscle/muscles in which you are feeling the sensations by clicking on the left-side image.
                </div>
              </>
            )}
            <div className="row">
              <Accordion>
                {anteriorZones.map((zone, index) => (
                  <>
                    {zone.selected && (
                      <>
                        <Card className="mb-1">
                          <Card.Header>
                            <CustomToggle eventKey={index} id={zone.id} muscle={zone.name}>
                              <strong>Muscle:</strong> {zone.name}{" "}
                            </CustomToggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey={index}>
                            <Card.Body>
                              <p className="card-text">
                                <div className="row">
                                  <div className="col-md-6">
                                    <b>Sensation:</b> {zone.sensation}
                                  </div>
                                  <div className="col-md-6">
                                    <b>Level:</b> {zone.level}
                                  </div>

                                  <div className="col-md-6 mt-0">
                                    <b>Zone:</b> {zone.treatment}
                                  </div>
                                  <div className="col-md-6 mt-0">
                                    <b>Location:</b> {zone.location}
                                  </div>
                                </div>

                                <p className="mb-2 mt-2">
                                  <b>Origin:</b> <small>{zone.origin}</small>
                                </p>
                                <p className="mb-2">
                                  <b>Insertion:</b> <small>{zone.insertion}</small>
                                </p>
                                <p className="mb-2">
                                  <b>Movement:</b> <small>{zone.movement}</small>
                                </p>
                                <p className="mb-2">
                                  <b>Actions:</b> <small>{zone.actions}</small>
                                </p>
                                <p>
                                  <b>Complaint:</b> <small>{zone.complaint}</small>
                                </p>
                              </p>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </>
                    )}
                  </>
                ))}
              </Accordion>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={() => setShow(false)} size="sm">
        <Modal.Header closeButton>
          <Modal.Title>{zoneData.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>Sensation level</label>
            <select className="form-control" value={sensation} onChange={(e) => setSensation(e.target.value)}>
              <option value=""> Select </option>
              <option value="1">01 - No discomfort, feeling good</option>
              <option value="2">02 - Mild discomfort</option>
              <option value="3">03 - Mild discomfort</option>
              <option value="4">04 - Moderate, noticeable discomfort but still manageable.</option>
              <option value="5">05 - Moderate, noticeable discomfort but still manageable.</option>
              <option value="6">06 - Moderate, noticeable discomfort but still manageable.</option>
              <option value="7">07 - Severe discomfort, significantly impacting movement or quality of life</option>
              <option value="8">08 - Severe discomfort, significantly impacting movement or quality of life</option>
              <option value="9">09 - Extreme, debilitating discomfort needing treatment</option>
              <option value="10">10 - Extreme, debilitating discomfort needing treatment</option>
            </select>
            <small className="form-text text-muted">Select numbers 1 to 10 to indicate your tolerance and functional capacity (sensation) levels.</small>
          </div>

          <div className="form-group">
            <label>Complaint</label>
            <textarea className="form-control" value={complaint} onChange={(e) => setComplaint(e.target.value)}></textarea>
          </div>

          <button type="submit" className="btn btn-primary w-100 mt-3" onClick={() => addZone()}>
            Add
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
}
