import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { Box, Text } from "./../../components/elements";
import axios from "axios";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import moment from "moment";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import ATSSystemAdd from "../blocks/atsSystemAdd";
import { setSelectedAtsTab, setSelectedAppointment } from "../../actions";
import { useDispatch } from "react-redux";
import ToggleButton from "react-toggle-button";

export default function Report({ male }) {
  const dispatch = useDispatch();
  const wrapper_ref = useRef();
  const { userData, token, saveIndicator, selectedAtsTab } = useSelector((state) => state.counter);
  const [activePage, setActivePage] = useState(1);
  const [perPage, setPerpage] = useState(100);
  const [totalRecod, setTotalRecod] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [atsShow, setAtsShow] = useState(false);
  const [initial, setInitial] = useState(false);
  const [pid, setId] = useState("");
  const [sending, setSending] = useState(false);

  useEffect(() => {
    fetchData(1);
  }, [saveIndicator]);

  async function fetchData(pageNumber) {
    setLoading(true);

    const urlString = window.location.search;
    const urlParams = new URLSearchParams(urlString);
    const id = urlParams.get("id");
    setId(id);
    const options = {
      headers: { authorization: token },
    };

    const params = {
      page: perPage * (pageNumber - 1),
      limit: perPage,
      patient: id,
      practitioner: userData._id,
      report: 1,
    };

    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    const response = await axios.get(process.env.REACT_APP_API_URL + "app/user-reports?" + queryString, options);
    if (response && response.data.success) {
      if (response.data.result) {
        setTotalRecod(response.data.totalCount);
        setData(response.data.result);
        setLoading(false);
      }
    } else {
      toast.error(response.data.message);
    }
  }

  // On Pagination Change
  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    fetchData(pageNumber);
  }

  async function printDocument() {
    const pages = wrapper_ref.current.querySelectorAll(".page");
    const pdf = new jsPDF("p", "mm", "a4");

    for (let i = 0; i < pages.length; i++) {
      const canvas = await html2canvas(pages[i], {
        useCORS: true,
        imageTimeout: 0,
        allowTaint: true,
        scale: 2,
        scrollX: -window.scrollX,
        scrollY: -window.scrollY,
        windowWidth: document.documentElement.offsetWidth,
        windowHeight: document.documentElement.offsetHeight,
      });

      const imgData = canvas.toDataURL("image/png");
      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      if (i > 0) pdf.addPage();
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
    }

    pdf.save("download.pdf");
  }
  useEffect(() => {
    fetchData(1);
    setActivePage(1);
  }, [atsShow]);

  async function handleDelete(pageNumber) {
    var confirmDelete = window.confirm("Are you sure you want to delete this item?");
    if (confirmDelete) {
      const options = {
        headers: { authorization: token },
      };

      const params = {
        id: pageNumber,
      };

      const queryString = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");

      const response = await axios.get(process.env.REACT_APP_API_URL + "app/delete-reports?" + queryString, options);
      if (response && response.data.success) {
        if (response.data.success) {
          fetchData(1);
          setActivePage(1);
        }
      } else {
        toast.error(response.data.message);
      }
    }
  }

  async function sendReport() {
    await axios.post(process.env.REACT_APP_API_URL + "app/appointment/send-report", {
      id: pid,
    });
    setSending(false);
    toast.success("Report sent");
  }

  return (
    <>
      <Row className="justify-content-center">
        <div className="row">
          <div className="col-md-3">
            <button
              onClick={() => {
                setAtsShow(true);
                if (data.length > 0) {
                  setInitial(false);
                } else {
                  setInitial(true);
                }

                dispatch(setSelectedAppointment(""));
              }}
              className="btn btn-primary w-100"
            >
              Add record
            </button>
          </div>
          <div className="col-md-3">
            <button
              onClick={() => {
                fetchData(1);
                setActivePage(1);
              }}
              className="btn btn-primary w-100"
            >
              Refresh Data
            </button>
          </div>
          <div className="col-md-3">
            <button
              onClick={() => {
                sendReport(pid);
                setSending(true);
              }}
              className="btn btn-primary w-100"
            >
              {sending ? "Sending..." : " Send Report"}
            </button>
          </div>
          <div className="col-md-3">
            <button onClick={() => printDocument()} className="btn btn-primary w-100">
              Print
            </button>
          </div>
        </div>

        <div className="alert alert-info text-center" role="alert">
          <b>&#9432;</b> Click on body to open the details
        </div>

        {loading && (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>{" "}
            Please wait...
          </div>
        )}

        <style>
          {`
          @media print {
            .page {
              page-break-before: always; /* Force page break before each image */
              break-inside: avoid;
            }
          }
        `}
        </style>

        <div id="divToPrint" className="print-container" ref={wrapper_ref}>
          {data.length > 0 &&
            data.map((item, key) => (
              <div key={key}>
                <Col xl={12}>
                  {key != 0 && (
                    <div>
                      <hr />
                    </div>
                  )}

                  <h5 className="text-center">
                    <button
                      onClick={() => {
                        setAtsShow(true);
                        dispatch(setSelectedAppointment(item._id));
                        setInitial(false);
                      }}
                      className="btn btn-primary btn-sm"
                    >
                      View
                    </button>
                    &nbsp;
                    <button
                      onClick={() => {
                        handleDelete(item._id);
                      }}
                      className="btn btn-primary btn-sm"
                    >
                      Delete
                    </button>
                  </h5>
                  <hr />
                  <Row className="justify-content-center" style={{ fontSize: 17 }}>
                    {item.anteriorViewMale && item.anteriorViewMale.length > 0 && (
                      <Col md={12} className="text-center page">
                        <div> {moment(item.createdAt).format("MMMM Do YYYY")} | Anterior View</div>
                        <div className="row">
                          <div className="col-md-6">
                            <img src={`${process.env.REACT_APP_IMG_URL}/body/${item._id}_basic_men_front.png?${new Date().getTime()}`} className="img-fluid" alt="Anterior View Male" />
                          </div>
                          <div className="col-md-6">
                            <br />
                            <br />
                            <div className="row">
                              {item.anteriorViewMale.map((zone) => (
                                <>
                                  {zone.selected && (
                                    <>
                                      <div className="col-md-6">
                                        <div className="card" key={zone.zone}>
                                          <div className="card-header" style={{ backgroundColor: zone.color, color: "#FFF" }}>
                                            <div className="float-start">Zone: {zone.zone}</div>
                                          </div>

                                          <div className="card-body">
                                            <p className="card-text" style={{ textAlign: "left" }}>
                                              <p>
                                                <b>Sensation:</b> {zone.sensation}
                                              </p>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </>
                              ))}
                            </div>
                          </div>
                        </div>

                        <div>
                          <div>
                            <br /> <img src="/images/logo_alt.png" className="img-fluid" style={{ height: 50 }} />
                          </div>
                          <small> 5001 Birch St Newport Beach, CA 92660 </small>
                        </div>
                      </Col>
                    )}
                    {item.posteriorViewMale && item.posteriorViewMale.length > 0 && (
                      <Col md={12} className="text-center page">
                        <div> {moment(item.createdAt).format("MMMM Do YYYY")} | Posterior View</div>
                        <div className="row">
                          <div className="col-md-6">
                            <img src={`${process.env.REACT_APP_IMG_URL}/body/${item._id}_basic_men_back.png?${new Date().getTime()}`} className="img-fluid" alt="Posterior View Male" />
                          </div>
                          <div className="col-md-6">
                            <br />
                            <br />
                            <div className="row">
                              {item.posteriorViewMale.map((zone) => (
                                <>
                                  {zone.selected && (
                                    <>
                                      <div className="col-md-6">
                                        <div className="card" key={zone.zone}>
                                          <div className="card-header" style={{ backgroundColor: zone.color, color: "#FFF" }}>
                                            <div className="float-start">Zone: {zone.zone}</div>
                                          </div>

                                          <div className="card-body">
                                            <p className="card-text" style={{ textAlign: "left" }}>
                                              <p>
                                                <b>Sensation:</b> {zone.sensation}
                                              </p>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </>
                              ))}
                            </div>
                          </div>
                        </div>

                        <div>
                          <div>
                            <br /> <img src="/images/logo_alt.png" className="img-fluid" style={{ height: 50 }} />
                          </div>
                          <small> 5001 Birch St Newport Beach, CA 92660 </small>
                        </div>
                      </Col>
                    )}
                    {item.anteriorViewFeMale && item.anteriorViewFeMale.length > 0 && (
                      <Col md={12} className="text-center page">
                        <div> {moment(item.createdAt).format("MMMM Do YYYY")} | Anterior View</div>
                        <div className="row">
                          <div className="col-md-6">
                            <img src={`${process.env.REACT_APP_IMG_URL}/body/${item._id}_basic_women_front.png?${new Date().getTime()}`} className="img-fluid" alt="Anterior View Female" />
                          </div>
                          <div className="col-md-6">
                            <br />
                            <br />
                            <div className="row">
                              {item.anteriorViewFeMale.map((zone) => (
                                <>
                                  {zone.selected && (
                                    <>
                                      <div className="col-md-6">
                                        <div className="card" key={zone.zone}>
                                          <div className="card-header" style={{ backgroundColor: zone.color, color: "#FFF" }}>
                                            <div className="float-start">Zone: {zone.zone}</div>
                                          </div>

                                          <div className="card-body">
                                            <p className="card-text" style={{ textAlign: "left" }}>
                                              <p>
                                                <b>Sensation:</b> {zone.sensation}
                                              </p>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </>
                              ))}
                            </div>
                          </div>
                        </div>

                        <div>
                          <div>
                            <br /> <img src="/images/logo_alt.png" className="img-fluid" style={{ height: 50 }} />
                          </div>
                          <small> 5001 Birch St Newport Beach, CA 92660 </small>
                        </div>
                      </Col>
                    )}
                    {item.posteriorViewFeMale && item.posteriorViewFeMale.length > 0 && (
                      <Col md={12} className="text-center page">
                        <div> {moment(item.createdAt).format("MMMM Do YYYY")} | Posterior View</div>
                        <div className="row">
                          <div className="col-md-6">
                            <img src={`${process.env.REACT_APP_IMG_URL}/body/${item._id}_basic_women_back.png?${new Date().getTime()}`} className="img-fluid" alt="Posterior View Female" />
                          </div>
                          <div className="col-md-6">
                            <br />
                            <br />
                            <div className="row">
                              {item.posteriorViewFeMale.map((zone) => (
                                <>
                                  {zone.selected && (
                                    <>
                                      <div className="col-md-6">
                                        <div className="card" key={zone.zone}>
                                          <div className="card-header" style={{ backgroundColor: zone.color, color: "#FFF" }}>
                                            <div className="float-start">Zone: {zone.zone}</div>
                                          </div>

                                          <div className="card-body">
                                            <p className="card-text" style={{ textAlign: "left" }}>
                                              <p>
                                                <b>Sensation:</b> {zone.sensation}
                                              </p>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </>
                              ))}
                            </div>
                          </div>
                        </div>

                        <div>
                          <div>
                            <br /> <img src="/images/logo_alt.png" className="img-fluid" style={{ height: 50 }} />
                          </div>
                          <small> 5001 Birch St Newport Beach, CA 92660 </small>
                        </div>
                      </Col>
                    )}
                    {item.anteriorViewMaleMuscle && item.anteriorViewMaleMuscle.length > 0 && (
                      <Col md={12} className="text-center page">
                        <div> {moment(item.createdAt).format("MMMM Do YYYY")} | Anterior View</div>
                        <div className="row">
                          <div className="col-md-6">
                            <img src={`${process.env.REACT_APP_IMG_URL}/body/${item._id}_muscle_men_front.png?${new Date().getTime()}`} className="img-fluid" alt="Anterior View Male Muscle" />
                          </div>
                          <div className="col-md-6">
                            <br />
                            <br />
                            <div className="row">
                              {item.anteriorViewMaleMuscle.map((zone) => (
                                <>
                                  {zone.selected && (
                                    <>
                                      <div className="col-md-6">
                                        <div className="card" key={zone.zone}>
                                          <div className="card-header" style={{ backgroundColor: zone.color, color: "#FFF" }}>
                                            <div className="float-start">Muscle: {zone.name}</div>
                                          </div>

                                          <div className="card-body">
                                            <p className="card-text" style={{ textAlign: "left" }}>
                                              <p>
                                                <b>Sensation:</b> {zone.sensation}
                                              </p>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </>
                              ))}
                            </div>
                          </div>
                        </div>

                        <div>
                          <div>
                            <br /> <img src="/images/logo_alt.png" className="img-fluid" style={{ height: 50 }} />
                          </div>
                          <small> 5001 Birch St Newport Beach, CA 92660 </small>
                        </div>
                      </Col>
                    )}
                    {item.posteriorViewMaleMuscle && item.posteriorViewMaleMuscle.length > 0 && (
                      <Col md={12} className="text-center page">
                        <div> {moment(item.createdAt).format("MMMM Do YYYY")} | Posterior View</div>
                        <div className="row">
                          <div className="col-md-6">
                            <img src={`${process.env.REACT_APP_IMG_URL}/body/${item._id}_muscle_men_back.png?${new Date().getTime()}`} className="img-fluid" alt="Posterior View Male Muscle" />
                          </div>
                          <div className="col-md-6">
                            <br />
                            <br />
                            <div className="row">
                              {item.posteriorViewMaleMuscle.map((zone) => (
                                <>
                                  {zone.selected && (
                                    <>
                                      <div className="col-md-6">
                                        <div className="card" key={zone.zone}>
                                          <div className="card-header" style={{ backgroundColor: zone.color, color: "#FFF" }}>
                                            <div className="float-start">Muscle: {zone.name}</div>
                                          </div>

                                          <div className="card-body">
                                            <p className="card-text" style={{ textAlign: "left" }}>
                                              <p>
                                                <b>Sensation:</b> {zone.sensation}
                                              </p>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </>
                              ))}
                            </div>
                          </div>
                        </div>

                        <div>
                          <div>
                            <br /> <img src="/images/logo_alt.png" className="img-fluid" style={{ height: 50 }} />
                          </div>
                          <small> 5001 Birch St Newport Beach, CA 92660 </small>
                        </div>
                      </Col>
                    )}
                    {item.posteriorViewFeMaleMuscle && item.posteriorViewFeMaleMuscle.length > 0 && (
                      <Col md={12} className="text-center page">
                        <div> {moment(item.createdAt).format("MMMM Do YYYY")} | Posterior View</div>
                        <div className="row">
                          <div className="col-md-6">
                            <img src={`${process.env.REACT_APP_IMG_URL}/body/${item._id}_muscle_women_front.png?${new Date().getTime()}`} className="img-fluid" alt="Anterior View Female Muscle" />
                          </div>
                          <div className="col-md-6">
                            <br />
                            <br />
                            <div className="row">
                              {item.posteriorViewFeMaleMuscle.map((zone) => (
                                <>
                                  {zone.selected && (
                                    <>
                                      <div className="col-md-6">
                                        <div className="card" key={zone.zone}>
                                          <div className="card-header" style={{ backgroundColor: zone.color, color: "#FFF" }}>
                                            <div className="float-start">Muscle: {zone.name}</div>
                                          </div>

                                          <div className="card-body">
                                            <p className="card-text" style={{ textAlign: "left" }}>
                                              <p>
                                                <b>Sensation:</b> {zone.sensation}
                                              </p>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </>
                              ))}
                            </div>
                          </div>
                        </div>

                        <div>
                          <div>
                            <br /> <img src="/images/logo_alt.png" className="img-fluid" style={{ height: 50 }} />
                          </div>
                          <small> 5001 Birch St Newport Beach, CA 92660 </small>
                        </div>
                      </Col>
                    )}
                    {item.anteriorViewFeMaleMuscle && item.anteriorViewFeMaleMuscle.length > 0 && (
                      <Col md={12} className="text-center page">
                        <div> {moment(item.createdAt).format("MMMM Do YYYY")} | Anterior View</div>
                        <div className="row">
                          <div className="col-md-6">
                            <img src={`${process.env.REACT_APP_IMG_URL}/body/${item._id}_muscle_women_back.png?${new Date().getTime()}`} className="img-fluid" alt="Posterior View Female Muscle" />
                          </div>
                          <div className="col-md-6">
                            <br />
                            <br />
                            <div className="row">
                              {item.anteriorViewFeMaleMuscle.map((zone) => (
                                <>
                                  {zone.selected && (
                                    <>
                                      <div className="col-md-6">
                                        <div className="card" key={zone.zone}>
                                          <div className="card-header" style={{ backgroundColor: zone.color, color: "#FFF" }}>
                                            <div className="float-start">Muscle: {zone.name}</div>
                                          </div>

                                          <div className="card-body">
                                            <p className="card-text" style={{ textAlign: "left" }}>
                                              <p>
                                                <b>Sensation:</b> {zone.sensation}
                                              </p>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </>
                              ))}
                            </div>
                          </div>
                        </div>

                        <div>
                          <div>
                            <br /> <img src="/images/logo_alt.png" className="img-fluid" style={{ height: 50 }} />
                          </div>
                          <small> 5001 Birch St Newport Beach, CA 92660 </small>
                        </div>
                      </Col>
                    )}
                    {item.rightFoot && item.rightFoot.length > 0 && (
                      <Col md={12} className="text-center page">
                        <div> {moment(item.createdAt).format("MMMM Do YYYY")} | Right Foot</div>
                        <div className="row">
                          <div className="col-md-6">
                            <img src={`${process.env.REACT_APP_IMG_URL}/body/${item._id}_leftFoot.png?${new Date().getTime()}`} className="img-fluid" alt="Right Foot" />
                          </div>
                          <div className="col-md-6">
                            <br />
                            <br />
                            <div className="row">
                              {item.rightFoot.map((zone) => (
                                <>
                                  {zone.selected && (
                                    <>
                                      <div className="col-md-6">
                                        <div className="card" key={zone.zone}>
                                          <div className="card-header" style={{ backgroundColor: zone.color, color: "#FFF" }}>
                                            <div className="float-start">Muscle: {zone.name}</div>
                                          </div>

                                          <div className="card-body">
                                            <p className="card-text" style={{ textAlign: "left" }}>
                                              <p>
                                                <b>Sensation:</b> {zone.sensation}
                                              </p>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </>
                              ))}
                            </div>
                          </div>
                        </div>

                        <div>
                          <div>
                            <br /> <img src="/images/logo_alt.png" className="img-fluid" style={{ height: 50 }} />
                          </div>
                          <small> 5001 Birch St Newport Beach, CA 92660 </small>
                        </div>
                      </Col>
                    )}
                    {item.leftFoot && item.leftFoot.length > 0 && (
                      <Col md={12} className="text-center page">
                        <div> {moment(item.createdAt).format("MMMM Do YYYY")} | Left Foot</div>
                        <div className="row">
                          <div className="col-md-6">
                            <img src={`${process.env.REACT_APP_IMG_URL}/body/${item._id}_rightFoot.png?${new Date().getTime()}`} className="img-fluid" alt="Left Foot" />
                          </div>
                          <div className="col-md-6">
                            <br />
                            <br />
                            <div className="row">
                              {item.leftFoot.map((zone) => (
                                <>
                                  {zone.selected && (
                                    <>
                                      <div className="col-md-6">
                                        <div className="card" key={zone.zone}>
                                          <div className="card-header" style={{ backgroundColor: zone.color, color: "#FFF" }}>
                                            <div className="float-start">Muscle: {zone.name}</div>
                                          </div>

                                          <div className="card-body">
                                            <p className="card-text" style={{ textAlign: "left" }}>
                                              <p>
                                                <b>Sensation:</b> {zone.sensation}
                                              </p>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </>
                              ))}
                            </div>
                          </div>
                        </div>

                        <div>
                          <div>
                            <br /> <img src="/images/logo_alt.png" className="img-fluid" style={{ height: 50 }} />
                          </div>
                          <small> 5001 Birch St Newport Beach, CA 92660 </small>
                        </div>
                      </Col>
                    )}
                    {item.leftHand && item.leftHand.length > 0 && (
                      <Col md={12} className="text-center page">
                        <div> {moment(item.createdAt).format("MMMM Do YYYY")} | Left Hand</div>
                        <div className="row">
                          <div className="col-md-6">
                            <img src={`${process.env.REACT_APP_IMG_URL}/body/${item._id}_leftHand.png?${new Date().getTime()}`} className="img-fluid" alt="Left Hand" />
                          </div>
                          <div className="col-md-6">
                            <br />
                            <br />
                            <div className="row">
                              {item.leftHand.map((zone) => (
                                <>
                                  {zone.selected && (
                                    <>
                                      <div className="col-md-6">
                                        <div className="card" key={zone.zone}>
                                          <div className="card-header" style={{ backgroundColor: zone.color, color: "#FFF" }}>
                                            <div className="float-start">Muscle: {zone.name}</div>
                                          </div>

                                          <div className="card-body">
                                            <p className="card-text" style={{ textAlign: "left" }}>
                                              <p>
                                                <b>Sensation:</b> {zone.sensation}
                                              </p>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </>
                              ))}
                            </div>
                          </div>
                        </div>

                        <div>
                          <div>
                            <br /> <img src="/images/logo_alt.png" className="img-fluid" style={{ height: 60 }} />
                          </div>
                          <small> 5001 Birch St Newport Beach, CA 92660 </small>
                        </div>
                      </Col>
                    )}
                    {item.rightHand && item.rightHand.length > 0 && (
                      <Col md={12} className="text-center page">
                        <div> {moment(item.createdAt).format("MMMM Do YYYY")} | Right Hand</div>
                        <div className="row">
                          <div className="col-md-6">
                            <img src={`${process.env.REACT_APP_IMG_URL}/body/${item._id}_rightHand.png?${new Date().getTime()}`} className="img-fluid" alt="Right Hand" />
                          </div>
                          <div className="col-md-6">
                            <br />
                            <br />
                            <div className="row">
                              {item.rightHand.map((zone) => (
                                <>
                                  {zone.selected && (
                                    <>
                                      <div className="col-md-6">
                                        <div className="card" key={zone.zone}>
                                          <div className="card-header" style={{ backgroundColor: zone.color, color: "#FFF" }}>
                                            <div className="float-start">Muscle: {zone.name}</div>
                                          </div>

                                          <div className="card-body">
                                            <p className="card-text" style={{ textAlign: "left" }}>
                                              <p>
                                                <b>Sensation:</b> {zone.sensation}
                                              </p>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </>
                              ))}
                            </div>
                          </div>
                        </div>

                        <div>
                          <div>
                            <br /> <img src="/images/logo_alt.png" className="img-fluid" style={{ height: 60 }} />
                          </div>
                          <small> 5001 Birch St Newport Beach, CA 92660 </small>
                        </div>
                      </Col>
                    )}
                    {item.internalCavity && item.internalCavity.length > 0 && (
                      <Col md={12} className="text-center page">
                        <div> {moment(item.createdAt).format("MMMM Do YYYY")} | Internal Cavity</div>
                        <div className="row">
                          <div className="col-md-6">
                            <img src={`${process.env.REACT_APP_IMG_URL}/body/${item._id}_internal_cavity.png?${new Date().getTime()}`} className="img-fluid" alt="Internal Cavity" />
                          </div>
                          <div className="col-md-6">
                            <br />
                            <br />
                            <div className="row">
                              {item.internalCavity.map((zone) => (
                                <>
                                  {zone.selected && (
                                    <>
                                      <div className="col-md-6">
                                        <div className="card" key={zone.zone}>
                                          <div className="card-header" style={{ backgroundColor: zone.color, color: "#FFF" }}>
                                            <div className="float-start">Muscle: {zone.name}</div>
                                          </div>

                                          <div className="card-body">
                                            <p className="card-text" style={{ textAlign: "left" }}>
                                              <p>
                                                <b>Sensation:</b> {zone.sensation}
                                              </p>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </>
                              ))}
                            </div>
                          </div>
                        </div>

                        <div>
                          <div>
                            <br /> <img src="/images/logo_alt.png" className="img-fluid" style={{ height: 60 }} />
                          </div>
                          <small> 5001 Birch St Newport Beach, CA 92660 </small>
                        </div>
                      </Col>
                    )}
                    {item.diaphragm && item.diaphragm.length > 0 && (
                      <Col md={12} className="text-center page">
                        <div> {moment(item.createdAt).format("MMMM Do YYYY")} | Diaphragm</div>
                        <div className="row">
                          <div className="col-md-6">
                            <img src={`${process.env.REACT_APP_IMG_URL}/body/${item._id}_diaphragm.png?${new Date().getTime()}`} className="img-fluid" alt="Diaphragm" />
                          </div>
                          <div className="col-md-6">
                            <br />
                            <br />
                            <div className="row">
                              {item.diaphragm.map((zone) => (
                                <>
                                  {zone.selected && (
                                    <>
                                      {console.log(zone)}
                                      <div className="col-md-6">
                                        <div className="card" key={zone.zone}>
                                          <div className="card-header" style={{ backgroundColor: zone.color, color: "#FFF" }}>
                                            <div className="float-start">Muscle: {zone.name}</div>
                                          </div>

                                          <div className="card-body">
                                            <p className="card-text" style={{ textAlign: "left" }}>
                                              <p>
                                                <b>Sensation:</b> {zone.sensation}
                                              </p>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </>
                              ))}
                            </div>
                          </div>
                        </div>

                        <div>
                          <div>
                            <br /> <img src="/images/logo_alt.png" className="img-fluid" style={{ height: 60 }} />
                          </div>
                          <small> 5001 Birch St Newport Beach, CA 92660 </small>
                        </div>
                      </Col>
                    )}
                  </Row>
                </Col>
              </div>
            ))}
        </div>
      </Row>

      <Box className="mc-paginate">
        <Text className="mc-paginate-title">
          Total <b>{totalRecod}</b> Results Found
        </Text>
        <nav aria-label="Page navigation example">
          {totalRecod > perPage ? (
            <Pagination
              prevPageText="prev"
              nextPageText="next"
              firstPageText="first"
              lastPageText="last"
              activePage={activePage}
              itemsCountPerPage={perPage}
              totalItemsCount={totalRecod}
              onChange={handlePageChange}
              itemClass="page-item"
              linkClass="page-link"
              innerClass="pagination justify-content-center"
            />
          ) : (
            ""
          )}
        </nav>
      </Box>

      <style>
        {`
          @media print {
            .print-container {
              width: 210mm;
              margin: 0 auto;
            }
            .page {
              page-break-before: always;
              break-inside: avoid;
            }
          }
          .text-center {
            text-align: center;
          }
          .img-fluid {
            max-width: 100%;
            height: auto;
          }
        `}
      </style>

      <Modal show={atsShow} onHide={() => setAtsShow(false)} fullscreen={true}>
        <Modal.Header>
          <div className="row w-100">
            <div className="col-md-6">
              <Modal.Title>
                Manage record ({initial ? "Zone" : "Muscle"})
                {saveIndicator ? (
                  <small className="p-1 bg-warning rounded" style={{ fontSize: 13 }}>
                    Syncing Data. Please Wait...
                  </small>
                ) : (
                  ""
                )}
              </Modal.Title>
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-4">
              <div class="btn-group" role="group">
                <button type="button" class="btn btn-white text-dark nobtn">
                  Show Zone
                </button>
                <button type="button" class="btn btn-white">
                  <ToggleButton
                    inactiveLabel={"No"}
                    activeLabel={"Yes"}
                    value={initial}
                    onToggle={(value) => {
                      value ? setInitial(false) : setInitial(true);
                    }}
                  />
                </button>
                <button
                  type="button"
                  class="btn btn-success"
                  onClick={() => {
                    if (selectedAtsTab == "HealthRecord") {
                      // setAtsShow(false);
                      dispatch(setSelectedAtsTab("AnteriorView"));

                      toast.success("Data has been submitted successfully");
                    } else {
                      setAtsShow(false);
                      toast.success("Data has been submitted successfully");
                    }
                  }}
                >
                  Submit
                </button>
                <button type="button" class="btn btn-primary" onClick={() => setAtsShow(false)}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <ATSSystemAdd initial={initial} male={male} />

          <div className="text-center mt-3">
            <div class="btn-group" role="group">
              <button
                type="button"
                class="btn btn-success"
                onClick={() => {
                  if (selectedAtsTab == "HealthRecord") {
                    // setAtsShow(false);
                    dispatch(setSelectedAtsTab("AnteriorView"));

                    toast.success("Data has been submitted successfully");
                  } else {
                    setAtsShow(false);
                    toast.success("Data has been submitted successfully");
                  }
                }}
              >
                Submit
              </button>

              <button type="button" class="btn btn-primary" onClick={() => setAtsShow(false)}>
                Close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
